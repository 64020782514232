import React, {useEffect} from "react";
import { connect } from "react-redux";
import { getLessons } from "../../requestToken/token";
import Loader from "../Loader/Loader";
import UserOneLessons from "../UserOneLessons/UserOneLessons";
import classes from './UserInfo.module.scss'
import telegram from '../../img/telegram.svg' 
import facebook from '../../img/facebook.svg'
import instagram from '../../img/instagram.svg'

const UserInfo = (props) => {
    // console.log('component user info', props.data.lessons)
    // useEffect(() => {
    //     getLessons()
    // },[])
    return (
        <div style={{height: '100%'}}>
            {props.data.lessons 
            ?
                <div className={classes.userInfo}>
                    <div className="g-container">
                        <div className={classes.userInfo__wrapper}>
                            <div className={classes.userInfo__wrapper__title}>
                                <h1 className={classes.userInfo__wrapper__title_header}>Ваши занятия</h1>
                            </div>
                            {props.data.lessons.length ?
                                <div className={classes.userInfo__wrapper__scheduleWrap}>
                                    <div className={classes.userInfo__wrapper__schedule}>
                                        <div className={classes.userInfo__wrapper__schedule__title}>
                                            <div className={classes.userInfo__wrapper__schedule__title_lessons}>Занятие</div>   
                                            <div className={classes.userInfo__wrapper__schedule__title_date}>Дата</div> 
                                            <div className={classes.userInfo__wrapper__schedule__title_time}>Время</div>  
                                        </div>
                                        {props.data.lessons.map((el) => {
                                            return(
                                                <UserOneLessons
                                                    key = {el.key} 
                                                    time = {el.time}
                                                    day = {el.day}
                                                    month = {el.month}
                                                    year = {el.year}
                                                    type = {el.type}
                                                    dayWeek = {el.numberDayWeek}
                                                    paid = {el.paid}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className={classes.userInfo__wrapper__schedule__incription}>
                                        <div className={classes.userInfo__wrapper__schedule__incription_paid}/>
                                        <p className={classes.userInfo__wrapper__schedule__incription_text}>Занятие оплачено</p>

                                        <div className={classes.userInfo__wrapper__schedule__incription_unpaid}/>
                                        <p className={classes.userInfo__wrapper__schedule__incription_text}>Занятие НЕ оплачено</p>
                                    </div>
                                </div>
                            :   
                                <div className={classes.userInfo__wrapper__empty}><p>У вас пока нет занятий</p></div>
                            }
                            <div className={classes.userInfo__wrapper__footer}>
                                <p className={classes.userInfo__wrapper__footer_text}>Остались вопросы? Напиши нам</p>
                                <div className={classes.userInfo__wrapper__footer__wrapper}>
                                    <a href="https://instagram.com/studio_romani?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
                                        <img className={classes.userInfo__wrapper__footer__wrapper_img} alt='instagram' src={instagram}/>
                                    </a>
                                    <a href="https://t.me/studio_romani" target="_blank" rel="noreferrer">
                                        <img className={classes.userInfo__wrapper__footer__wrapper_img} alt='telegram' src={telegram}/>
                                    </a>
                                    <a href="https://www.facebook.com/profile.php?id=100077656080031" target="_blank" rel="noreferrer">
                                        <img className={classes.userInfo__wrapper__footer__wrapper_img} alt='facebook' src={facebook}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
            <div className={classes.loadinWrapper}>
                <Loader />
            </div>
            }
        </div>
    )
}

// export default UserInfo

const mapStateToProps = (state) => ({
    data: state.UserLessons
})

export default connect(mapStateToProps, {})(UserInfo)