import React from "react";
import classes from './ItemShowAdminUserList.module.scss'

const ItemShowAdminUserList = (props) => {
    
    return(
        <div className={classes.list} onClick={props.closeForm}>
            <p className={classes.list_name}>{props.name}</p>
            <p className={classes.list_surname}>{props.surname}</p>
        </div>
    )
}

export default ItemShowAdminUserList