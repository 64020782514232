const USER_LESSONS =  'USER_LESSONS'
const ADMIN_LESSONS =  'ADMIN_LESSONS'
const GET_USERS_LIST = 'GET_USERS_LIST'
const GOT_LESSONS_LIST = 'GOT_LESSONS_LIST'
const MAKE_ADMIN_SCHEDUALED_LIST = 'MAKE_ADMIN_SCHEDUALED_LIST'
const LOADING_ADD_LESSON = 'LOADING_ADD_LESSON'
const SHOW_USER_LESSONS_TO_ADMIN = 'SHOW_USER_LESSONS_TO_ADMIN'
const HAVE_USER_LESSONS_TO_ADMIN = 'HAVE_USER_LESSONS_TO_ADMIN'
const TURN_OFF_LOADING_LESSON = 'TURN_OFF_LOADING_LESSON'

const initialState = {
    lessons: '',
    adminLessons: [],
    listUsers: [],
    isLoadLessons: false,
    schedualedAdminList: [],
    isLoadingAddLesson: false,
    getUserLessonsToAdmin: [],
    isUserLessonsToAdmin: false,
    isChangingLessonData: false,
}

const UserLessons = (state = initialState, action) => {
    switch(action.type){
        case USER_LESSONS:
            return{
                ...state,
                lessons: [...action.lessonsArray]
            }
        case ADMIN_LESSONS:
            return{
                ...state,
                adminLessons: action.adminLessons
            }
        case GET_USERS_LIST:
            return{
                ...state,
                listUsers: action.listUsers
            }
        case GOT_LESSONS_LIST: 
            return{
                ...state,
                isLoadLessons: true
            }
        case MAKE_ADMIN_SCHEDUALED_LIST: 
        // console.log('final', action.arrayDays)
            return{
                ...state,
                schedualedAdminList: action.arrayDays
            }
        case LOADING_ADD_LESSON: 
            return{
                ...state,
                isLoadingAddLesson: action.condition
            }
        case SHOW_USER_LESSONS_TO_ADMIN: 
            return{
                ...state,
                getUserLessonsToAdmin: action.futureLessons
            }
        case HAVE_USER_LESSONS_TO_ADMIN: 
            return{
                ...state,
                isUserLessonsToAdmin: action.status
            }
        case TURN_OFF_LOADING_LESSON :
            return{
                ...state,
                isChangingLessonData: action.status
            }
        default:
            return state;
    }
}


// export const adminLessons = () => {
//     return (dispatch) => {

//     }
// }
export const isLoadedLessons = () => ({type: GOT_LESSONS_LIST})
export const startAddingLesson = (condition) => ({type: LOADING_ADD_LESSON, condition})
export const setLoadingUsersLessonsToAdmin = (status) => ({type: LOADING_ADD_LESSON, status})
export const setFalseUserLessonToAdmin = () => ({type: HAVE_USER_LESSONS_TO_ADMIN, status:false})
export const setTurnOffLoadingLesson = (status) => ({type: TURN_OFF_LOADING_LESSON, status})

export default UserLessons