import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { setLoadingUsersLessonsToAdmin, setTurnOffLoadingLesson } from "../../bll/UserLessons";
import ReactDatePicker from "react-datepicker";
import classes from './AdminSetLessons.module.scss'
import "react-datepicker/dist/react-datepicker.css";
import ItemShowAdminUserList from "../ItemShowAdminUserList/ItemShowAdminUserList";
import TimePicker from 'react-time-picker';
import jwt_decode from "jwt-decode";
import { addUserLessonsFromAdmin } from "../../requestToken/token";
import Loader from "../Loader/Loader";

const AdminSetLessons = (props) => {

    // Object.keys(props.userList[props.userList.length - 1])
    // unprocessed_day_list = Object.values(arrayDays[arrayDays.length - 1])[0]
    const [startDate, setStartDate] = useState(null);
    const [showUserList, setShowUserList] = useState(false)
    const [userInfo, setUserIngo] = useState('')
    const [userName, setUserName] = useState('')
    const [value, onChange] = useState('');
    const [isSing, setSing] = useState(true);
    const [isPaid, setPaid] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const [nameUser, setNameUser] = useState('');
    const [surnameUser, setSurnameUser] = useState('');

    useEffect(() => {
        if(value && userInfo && startDate){
            setDisabled(false)
        }
        else(
            setDisabled(true)
        )
    },[value, userInfo, startDate ]) 
    
    const handleShowList = (email, name, surname) => {
        setShowUserList(prev => !prev)
        setUserIngo(email)
        setUserName(name + " " + surname)
        setNameUser(name)
        setSurnameUser(surname)
    }

    const handleForm = () => {
        props.onClick()
        props.setTurnOffLoadingLesson(true)
        const adminEmail = jwt_decode(localStorage.getItem('token')).email;
        const date = Date.parse(startDate)/ 1000
        const [hours, minutes] = value.split(':');
        const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60;
        const sum = date + totalSeconds
        addUserLessonsFromAdmin(adminEmail, userInfo, nameUser, surnameUser, isSing, isPaid, sum)
        setUserName('')
        setUserIngo('')
        setUserName('')
        setNameUser('')
        setSurnameUser('')
        setStartDate(null)
        onChange('')
    }

    return !props.reducer.isChangingLessonData ? (
        <div className={classes.student}>
            <div className="g-container">
                {!props.isLoading
                ? 
                    <div className={classes.student__wrapper}>
                    {/* <div className={classes.student__wrapper__chooseStudent}></div> */}
                    <input 
                        className={classes.student__wrapper_chooseStudent}
                        placeholder={'Выберите ученика'}
                        onClick={() => setShowUserList(prev => !prev)}
                        value={userName}
                        onChange={() => {}}
                    />
                    {showUserList &&
                        props.userList &&
                        <div className={classes.student__wrapper_usersList}>
                            {props.userList.map((el) => {
                                return(
                                    // <p className={classes.student__wrapper_usersList_item}>{el['email']}</p>
                                    <ItemShowAdminUserList 
                                        key={el.email}
                                        email = {el.email}
                                        name = {el.name}
                                        surname = {el.surname}
                                        closeForm = {() => handleShowList(el.email, el.name, el.surname)}
                                    />
                                )
                            })}
                        </div>
                    }
                    <ReactDatePicker 
                        selected={startDate} 
                        onChange={date => setStartDate(date)}
                        placeholderText = {'Выберите дату'}
                        dateFormat='dd.MM.yyyy'
                        minDate={new Date()}
                        className={classes.student__wrapper_date}/>
                    <div className={classes.student__wrapper_time}>
                        <TimePicker onChange={onChange} value={value} clockIcon={null} disableClock={true} />
                    </div>
                    <div className={classes.student__wrapper__checkBoxes}>
                        <div className={classes.student__wrapper__checkBoxes}>
                            <input type='checkbox' checked={isSing} onChange={() => setSing(prev => !prev)}/>
                            <p>Вокальное занятие</p>
                        </div>
                        <div className={classes.student__wrapper__checkBoxes}>
                            <input type='checkbox' checked={isPaid} onChange={() => setPaid(prev => !prev)}/>
                            <p>Занятие оплачено</p>
                        </div>
                    </div>  
                    <button 
                        onClick = {handleForm} 
                        className={classes.student__wrapper_button} disabled={isDisabled}>
                            Добавить урок
                    </button>
                </div>
                :
                <div className={classes.loadinWrapper}>
                    <Loader />
                </div>
                }
            </div>
        </div>
    )
    : 
    (
        <div className={classes.loadinWrapper}>
            <Loader />
        </div>
    )
}

// export default AdminSetLessons

const mapStateToProps = (state) => ({
    data: state.UserLessons,
    reducer: state.UserLessons
})

export default connect(mapStateToProps, {setLoadingUsersLessonsToAdmin, setTurnOffLoadingLesson})(AdminSetLessons)