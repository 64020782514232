import jwt_decode from "jwt-decode";
import { db } from '../firebase'
import { addDoc, collection, getDocs, query, where, Timestamp, getDoc, deleteDoc, doc, updateDoc} from 'firebase/firestore'
import { store } from '../bll/store';

export const isToken = () => {
    return localStorage.getItem('token')
}

export const getEmailByToken = () => {
    if(localStorage.getItem('token')){
        const token = localStorage.getItem('token')
        const decode1 = jwt_decode(token);
        return decode1.email
    }
}

export const getIdCollection = async (email) => {
    const users = collection(db, "users");
    const myUser = query(users, where("email", "==", email));
    const querySnapshot = await getDocs(myUser);
    return querySnapshot.docs[0].id
}

export const createDocumentCollection = async (email, name, surname)  => {
    const isAdmin = false;
    const usersCollectionRef = await collection(db, "users")
    addDoc(usersCollectionRef, { email, name, surname, isAdmin})
    const data = await getDocs(usersCollectionRef);
}

export const getUserInfo = async (email) => {
    const users = collection(db, "users");
    const myUser = query(users, where("email", "==", email));
    const querySnapshot = await getDocs(myUser);
    // console.log(querySnapshot.docs[0].data()) // получение всеъ данных о пользователе в айди коллекции
    // console.log(querySnapshot.docs[0].id) // получение айди коллекции
    const emailUser = querySnapshot.docs[0].data().email
    const userName = querySnapshot.docs[0].data().name
    const userSurname = querySnapshot.docs[0].data().surname
    const isAdmin = querySnapshot.docs[0].data().isAdmin
    store.dispatch({ type: 'MAIN_USER_INFO', emailUser, userName, userSurname, isAdmin })
}

export const getLessons  = async () => { // получить все уроки
    const id = await getIdCollection(getEmailByToken())
    const query = collection(db, "users", id, "lessons");
    const snapShot = await getDocs(query)
    // console.log(snapShot.docs[0].data().date)
    const lessonsArray = []
    snapShot.docs.map((el) => {
        // console.log(el._key.path.segments[8])
        const arr = []
        const currentDate = Math.round(new Date() / 1000)
        if(currentDate < el.data().date.seconds){ //проверка уроков у админа на существующие
            arr['time'] = el.data().date.toDate().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
            arr['day'] = el.data().date.toDate().getDate()
            arr['month'] = convertMonth(el.data().date.toDate().getMonth())
            arr['year'] = el.data().date.toDate().getFullYear()
            arr['numberDayWeek'] = convertDayWeek(el.data().date.toDate().getDay())
            arr['type'] = el.data().type
            arr['paid'] = el.data().paid
            arr['key'] = el.data().date.seconds
            arr['date'] = convertToDate(el.data().date.toDate())
            arr['idCollection'] = el._key.path.segments[8]
            if(el.data().name){
                arr['name'] = el.data().name
                arr['surname'] = el.data().surname
            }
            lessonsArray.push(arr)
        }
        // else{
        //     console.log('false old')
        // }
    })
    lessonsArray.sort(function(a,b){
        return a['key'] - b['key']
    })
    store.dispatch({ type: 'USER_LESSONS', lessonsArray })
    store.dispatch({ type: 'GOT_LESSONS_LIST'})
    getAdminLessons(lessonsArray)
}  

export const getAdminLessons = (lessons) => {
    sortByDate(lessons) 
    let day = new Date(); 
    day.setDate(day.getDate() + 0); 
    let currentDate = convertToDate(day)
    const arrayLessons = [];
    let count = 0
    lessons.map((el) => {
        for(let i = 0; i < 30; i++){
            if(el.date === currentDate)
            {   
                if(arrayLessons[currentDate])
                {
                    arrayLessons[currentDate].push([el])
                }
                else{
                    const obj = {[currentDate]: el}
                    arrayLessons.push(obj)
                    // arrayLessons[currentDate] = [el]
                }
                break
            }
            else{
                count = count + 1
                day = new Date()
                currentDate = convertToDate(day.setDate(day.getDate() + count));
                // console.log('carry on',el.date , currentDate, count)
            }   
        }
    })
    // console.log('finish', arrayLessons)
    store.dispatch({ type: 'ADMIN_LESSONS', adminLessons: arrayLessons })
    makeScheduleAdminLessons()
}

export const getUsersList = async () => {
    const users = collection(db, "users");
    const arrAll = await getDocs(users)
    const arrayUsers = []
    arrAll.docs.map((el) => {
        if(!el.data().isAdmin){
            arrayUsers.push(el.data())
        }
    })
    store.dispatch({ type: 'GET_USERS_LIST', listUsers: arrayUsers })
}

export const convertDayWeek = (day) => {
    switch(day){
        case 1: 
            return 'Пнд / '
        case 2: 
            return 'Втр / '
        case 3: 
            return 'Срд / '
        case 4: 
            return 'Чтв / '
        case 5: 
            return 'Птц / '
        case 6: 
            return 'Суб / '
        case 0: 
            return 'Вскр / '
        default:
            return ''
    }
}

export const adminDayWeek = (day) => {
    switch(day){
        case 1: 
            return 'Понедельник'
        case 2: 
            return 'Вторник'
        case 3: 
            return 'Среда'
        case 4: 
            return 'Четверг'
        case 5: 
            return 'Пятница'
        case 6: 
            return 'Суббота'
        case 0: 
            return 'Воскресенье'
        default:
            return ''
    }
}

export const convertMonth = (month) => {
    switch(month){
        case 0: 
            return '.01.'
        case 1: 
            return '.02.'
        case 2: 
            return '.03.'
        case 3: 
            return '.04.'
        case 4: 
            return '.05.'
        case 5: 
            return '.06.'
        case 6: 
            return '.07.'
        case 7: 
            return '.08.'
        case 8: 
            return '.09.'
        case 9: 
            return '.10.'
        case 10: 
            return '.11.'
        case 11: 
            return '.12.'
        default:
            return ''
    }
}

export const adminConvertMonth = (month) => {
    switch(month){
        case 0: 
            return '.01'
        case 1: 
            return '.02'
        case 2: 
            return '.03'
        case 3: 
            return '.04'
        case 4: 
            return '.05'
        case 5: 
            return '.06'
        case 6: 
            return '.07'
        case 7: 
            return '.08'
        case 8: 
            return '.09'
        case 9: 
            return '.10'
        case 10: 
            return '.11'
        case 11: 
            return '.12'
        default:
            return ''
    }
}

export const convertToDate = (day) => {
    return ("" + (new Date(day)).toISOString()).replace(/^([^T]+)T(.+)$/,'$1')
}

export const sortByDate = (array) => {
    return (
        array.sort(function(a, b){
            return a.key-b.key
          })
    )
}

export const addUserLessonsFromAdmin = async (adminEmail, userEmail, userName, userSurname, isSing, isPaid, timestamp) => {
    // console.log(adminEmail, userEmail, userName, userSurname, isSing, isPaid, timestamp)
    const type = isSing ? 'Вокал' : 'Театр' 
    const dateLesson = new Date(timestamp * 1000)
    addLessonsToAdmin(adminEmail, type, userName, isPaid, userSurname, dateLesson)
    addLessonToUser(userEmail, userName, userSurname, type, isPaid, dateLesson)
    store.dispatch({ type: 'LOADING_ADD_LESSON', condition: false })
}

export const addLessonsToAdmin = async (adminEmail, type, userName, isPaid, userSurname, dateLesson) => {
    try{
        const id = await getIdCollection(adminEmail)
        const usersCollectionRef = await collection(db, "users", id, "lessons")
        await addDoc(usersCollectionRef, { date: Timestamp.fromDate(new Date(dateLesson)), name: userName, paid: isPaid, surname: userSurname, type: type})
        const data = await getDocs(usersCollectionRef);
        getLessons()
        // getAdminLessons(store.getState().UserLessons.lessons)
        // makeScheduleAdminLessons()
    }
    catch{
        console.log('error')
    }
}

export const addLessonToUser = async (userEmail, userName, userSurname, type, isPaid, dateLesson) => {
    const id = await getIdCollection(userEmail)
    const usersCollectionRef = await collection(db, "users", id, "lessons")
    await addDoc(usersCollectionRef, { date: Timestamp.fromDate(new Date(dateLesson)), name: userName, paid: isPaid, surname: userSurname, type: type})
} 


export const makeScheduleAdminLessons = async () => {
    const arrayDays = []
    // console.log('store2', store.getState().UserLessons.adminLessons)
    const arrayLessons = store.getState().UserLessons.adminLessons.slice()
    for (let i = 0; i < 30; i ++){ 
        let day = new Date();
        day.setDate(day.getDate() + i);
        const currDay =  convertToDate(day)
        if(arrayLessons.length > 0){ //  если у админа осталиcь еще уроки
            let keys = Object.keys(arrayLessons[0])[0]
            let data = Object.values(arrayLessons[0])[0]
            if(currDay === keys){
                if(arrayDays.length === 0){
                    const newObj = {[currDay]: [data]}
                    arrayDays.push(newObj)
                    arrayLessons.shift()
                    i = i-1
                }
                else{
                    const key = Object.keys(arrayDays[arrayDays.length - 1])[0]
                    if(key === currDay){
                        const unprocessed_day_list = Object.values(arrayDays[arrayDays.length - 1])[0]
                        unprocessed_day_list.push(data);
                    }
                    else{
                        const newObj = {[currDay]: [data]}
                        arrayDays.push(newObj)
                    }
                    arrayLessons.shift()
                    i = i-1
                }
            }
            else{
                
                if(arrayDays.length === 0){     
                          
                    const newObj = {[currDay]: []}
                    arrayDays.push(newObj)
                }
                else if(arrayDays.length > 0){
                    
                    const k = Object.keys(arrayDays[arrayDays.length - 1])[0]
                    if(currDay !== k){
                        const newObj = {[currDay]: []}
                        arrayDays.push(newObj)
                    }
                }
            }
        }
        else{
            if(!arrayDays.length) { // если массив будущих уроков не пустой 
                const newObj = {[currDay]: []}
                arrayDays.push(newObj)
            }
            else{
                const k = Object.keys(arrayDays[arrayDays.length - 1])[0] //показать дату последнего дня
                if(currDay !== k){ //если в этот день уроков нету
                    const newObj = {[currDay]: []}
                    arrayDays.push(newObj)
                }
            }
        }
    } 
    store.dispatch({ type: 'MAKE_ADMIN_SCHEDUALED_LIST', arrayDays })
    store.dispatch({ type: 'TURN_OFF_LOADING_LESSON', status: false })
}

export const getUserFutureLessons  = async (email) => { // получить future уроки
    const id = await getIdCollection(email)
    const query = collection(db, "users", id, "lessons");
    const snapShot = await getDocs(query)
    const lessonsArray = []
    snapShot.docs.map((el) => {
        const arr = []
        const currentDate = Math.round(new Date() / 1000)
        // console.log('admin',el.data())
        if(currentDate < el.data().date.seconds){ //проверка уроков у админа на существующие
            arr['time'] = el.data().date.toDate().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
            arr['day'] = el.data().date.toDate().getDate()
            arr['month'] = convertMonth(el.data().date.toDate().getMonth())
            arr['year'] = el.data().date.toDate().getFullYear()
            arr['numberDayWeek'] = convertDayWeek(el.data().date.toDate().getDay())
            arr['type'] = el.data().type
            arr['paid'] = el.data().paid
            arr['key'] = el.data().date.seconds
            arr['date'] = convertToDate(el.data().date.toDate())
            arr['idCollection'] = el._key.path.segments[8]
            if(el.data().name){
                arr['name'] = el.data().name
                arr['surname'] = el.data().surname
            }
            lessonsArray.push(arr)
        }
        // else{
        //     console.log('false old')
        // }
    })
    lessonsArray.sort(function(a,b){
        return a['key'] - b['key']
    })
    // console.log('finish', lessonsArray)
    store.dispatch({ type: 'SHOW_USER_LESSONS_TO_ADMIN', futureLessons: lessonsArray })
    store.dispatch({ type: 'HAVE_USER_LESSONS_TO_ADMIN', status: true })
    // store.dispatch({ type: 'GOT_LESSONS_LIST'})
} 

export const convertKeyToTimestamp = (time) => {
    const firebaseTimestamp = time + '000'
    return +firebaseTimestamp
}

export const fitdIDCollectionOneLesson = async () => {
    //1667322000
    //1664204400
    //1667300400
    //1666969200
    const users = collection(db, "users", "JW0IbAQutVYZ6M3D5D7V", "lessons");
    // const myUser = query(users, where("paid", "==", true));
    const dateFormat = new Date(1667300400000);
    // console.log(dateFormat)
    const myUser = query(users, where("date", "==", dateFormat));
    const querySnapshot = await getDocs(myUser);
    // console.log(querySnapshot.docs[0])
    // const snapShot = await getDocs(users)
    
    // console.log(querySnapshot.docs[0].data())

    // console.log(querySnapshot.docs[0].data())
}

export const deleteuserLessonByUser = async (id, timestamp, name, surname) => {
    const users = collection(db, "users");
    const myUser = query(users, where("name", "==", name), where("surname", "==", surname));
    const querySnapshot = await getDocs(myUser);
    const userId = querySnapshot.docs[0].id
    const userEmail = querySnapshot.docs[0].data().email
    // console.log(userEmail)
    const docRef = doc(db, "users", userId, "lessons", id)
    await deleteDoc(docRef)
    .then(() => {
        deleteuserLessonByAdmin(timestamp, userEmail)
    })
}

export const deleteuserLessonByAdmin = async (timestamp, email) => {
    const users = collection(db, "users", "JW0IbAQutVYZ6M3D5D7V", "lessons");
    const dateFormat = new Date(timestamp);
    const myUser = query(users, where("date", "==", dateFormat));
    const querySnapshot = await getDocs(myUser);
    const userId = querySnapshot.docs[0].id
    const docRef = doc(db, "users", "JW0IbAQutVYZ6M3D5D7V", "lessons", userId)
    await deleteDoc(docRef)
    .then(() => {
        getUserFutureLessons(email)
        getLessons()
        // getAdminLessons()
    })
}


export const paidUpdateUserLessonByUser = async (id, timestamp, name, surname, status) => {
    const users = collection(db, "users");
    const myUser = query(users, where("name", "==", name), where("surname", "==", surname));
    const querySnapshot = await getDocs(myUser);
    const userId = querySnapshot.docs[0].id
    const userEmail = querySnapshot.docs[0].data().email
    // console.log(userEmail)
    const docRef = doc(db, "users", userId, "lessons", id)
    const paid = {paid: status}
    await updateDoc(docRef, paid)
    .then(() => {
        // console.log('success')
        paidUpdateUserLessonByAdmin(timestamp, userEmail, status)
    })
}

export const paidUpdateUserLessonByAdmin = async (timestamp, email, status) => {
    const users = collection(db, "users", "JW0IbAQutVYZ6M3D5D7V", "lessons");
    const dateFormat = new Date(timestamp);
    const myUser = query(users, where("date", "==", dateFormat));
    const querySnapshot = await getDocs(myUser);
    const userId = querySnapshot.docs[0].id
    const docRef = doc(db, "users", "JW0IbAQutVYZ6M3D5D7V", "lessons", userId)
    const paid = {paid: status}
    await updateDoc(docRef, paid)
    .then(() => {
        // console.log('success2')
        getUserFutureLessons(email)
        getLessons()
    })
}