import React, {useState, useEffect} from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import classes from './ForgotPassword.module.scss'
import { isToken } from "../../requestToken/token";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import hugeLogo from '../../img/hugeLogo.svg'
import telegram from '../../img/telegram.svg' 
import facebook from '../../img/facebook.svg'
import instagram from '../../img/instagram.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {

    const [email, setEmail] = useState('')
    const [isDisabled, setDisabled] = useState(true)

    useEffect( () => {
        if(email){
            setDisabled(false)
        }
        else{
            setDisabled(true)
        }
    }, [email])
    //https://admin.romanistudio.art
    //http://localhost:3000/signIn
    const handleForm = (email) => {
        sendPasswordResetEmail(auth, email, {url: 'https://admin.romanistudio.art/signIn'})
        .then( responce => {
            // console.log(responce)
            toast.success('Новый пароль отправлен на email')
            setDisabled(true)
            setEmail('')
        })
        .catch(responce => {
            // console.log(responce.message)
            // toast(responce.message)
            if(responce.message === 'Firebase: Error (auth/user-not-found).'){
                toast.error('Пользователь не найден')
            }
            else if(responce.message ===  'Firebase: Error (auth/invalid-email).'){
                toast.error('Такого email не существует')
            }
            else{
                toast.error('Ошибка. Повторите попытку')
            }
        })
    }
    
    return isToken() ? 
    (
      <Navigate to={{ pathname: "/Welcome"}}/>
    )
    : 
    (
        <div className={classes.forgot}>
            <div className="g-container">
                <div className={classes.forgot__wrapper}>
                    <div className={classes.forgot__wrapper__header}>
                        <img alt="logo" className={classes.forgot__wrapper__header_img} src={hugeLogo}/>
                        <div className={classes.forgot__wrapper__header__text}>
                            <p className={classes.forgot__wrapper__header__text_title}>Забыли пароль</p>
                            <Link to = '/signIn' className={classes.forgot__wrapper__header__text_link}>Вход</Link>
                        </div>
                    </div>
                    <div className={classes.forgot__wrapper__main}>
                        <input 
                            className={classes.forgot__wrapper__main_input}
                            type={'email'} 
                            value={email} 
                            onChange = {e => setEmail(e.target.value)} 
                            placeholder = 'Enter your email'/>
                         <button onClick = {() => handleForm(email)} className={classes.forgot__wrapper__main_singIn} disabled={isDisabled}>Сбросить пароль</button>
                    </div>
                    <div className={classes.forgot__wrapper__footer}>
                        <p className={classes.forgot__wrapper__footer_text}>Какие-то вопросы? Напиши нам</p>
                        <div className={classes.forgot__wrapper__footer__wrapper}>
                            <a href="https://instagram.com/studio_romani?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
                                <img className={classes.forgot__wrapper__footer__wrapper_img} alt='instagram' src={instagram}/>
                            </a>
                            <a href="https://t.me/studio_romani" target="_blank" rel="noreferrer">
                                <img className={classes.forgot__wrapper__footer__wrapper_img} alt='telegram' src={telegram}/>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100077656080031" target="_blank" rel="noreferrer">
                                <img className={classes.forgot__wrapper__footer__wrapper_img} alt='facebook' src={facebook}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer 
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"/>
        </div>
    )
}

export default ForgotPassword