import React, {useEffect, useState} from "react";
import classes from './AdminLessons.module.scss'
import { connect } from "react-redux";
import { convertToDate, makeScheduleAdminLessons } from "../../requestToken/token";
import ItemAdminOneDay from "../ItemAdminOneDay/ItemAdminOneDay";
import arrow from '../../img/arrowSide.svg'

const AdminLessons = (props) => {
    
    // const [lessonsList, setLessonsList] = useState([])
    const [showList, setShowList] = useState(props.reducer.schedualedAdminList.slice(0,3))
    const [left, setLeft] = useState(0)
    const [right, setRight] = useState(3)
    
    useEffect(() => {
        setShowList(props.reducer.schedualedAdminList.slice(0,3))
        // const arrayDays = []
        // const arrayLessons = props.lessons.slice()
        // console.log('arr', arrayLessons)
        // for (let i = 0; i < 10; i ++){ 
        //     let day = new Date();
        //     day.setDate(day.getDate() + i);
        //     const currDay =  convertToDate(day)
        //     if(arrayLessons.length > 0){ //  если у админа осталоиъъись еще уроки
        //         console.log('1')
        //         let keys = Object.keys(arrayLessons[0])[0]
        //         let data = Object.values(arrayLessons[0])[0]
    
        //         if(currDay === keys){
        //             if(arrayDays.length === 0){
        //                 const newObj = {[currDay]: [data]}
        //                 arrayDays.push(newObj)
        //                 arrayLessons.shift()
        //                 i = i-1
        //             }
        //             else{
        //                 const key = Object.keys(arrayDays[arrayDays.length - 1])[0]
        //                 if(key === currDay){
        //                     const unprocessed_day_list = Object.values(arrayDays[arrayDays.length - 1])[0]
        //                     unprocessed_day_list.push(data);
        //                 }
        //                 else{
        //                     const newObj = {[currDay]: [data]}
        //                     arrayDays.push(newObj)
        //                 }
        //                 arrayLessons.shift()
        //                 i = i-1
        //             }
        //         }
        //         else{
                    
        //             if(arrayDays.length === 0){     
                              
        //                 const newObj = {[currDay]: []}
        //                 arrayDays.push(newObj)
        //             }
        //             else if(arrayDays.length > 0){
                        
        //                 const k = Object.keys(arrayDays[arrayDays.length - 1])[0]
        //                 if(currDay !== k){
        //                     const newObj = {[currDay]: []}
        //                     arrayDays.push(newObj)
        //                 }
        //             }
        //         }
        //     }
        //     else{
        //         console.log('2')
        //         if(arrayDays) {
        //             console.log('3')  
        //             const newObj = {[currDay]: []}
        //             arrayDays.push(newObj)
        //         }
        //         else{
        //             console.log('4')
        //             const k = Object.keys(arrayDays[arrayDays.length - 1])[0] //показать дату последнего дня
        //             console.log('check', k)
        //             if(currDay !== k){ //если в этот день уроков нету
        //                 const newObj = {[currDay]: []}
        //                 arrayDays.push(newObj)
        //             }
        //         }
        //     }
        // } 
        // setLessonsList(arrayDays)
        // setShowList(arrayDays.slice(0,3))
    }, [props.reducer.schedualedAdminList])

    const handleList = (num) => {
        let doingList = []
        let first = left + num
        let second = right + num
        setLeft(prev => prev + num)
        setRight(prev => prev + num)
        // doingList = lessonsList.slice(first, second)
        doingList = props.reducer.schedualedAdminList.slice(first, second)
        setShowList(doingList)
    }

    return  (
        <div className={classes.schedule}>
            <div className="g-container">
                <div className={classes.schedule__wrapper}>
                    <div onClick={() => handleList(-1)} className={left === 0 ? classes.schedule__wrapper_hide : ''}>
                        <img src = {arrow} alt = 'arrow back' className={classes.schedule__wrapper_back}/>
                    </div>
                    <div  className={classes.schedule__wrapper_list}>
                        {/* {console.log('!!!!!',showList)} */}
                        {showList.map((el) => {
                            const key = Object.keys(el)
                            const data = Object.values(el)
                            return(
                                <ItemAdminOneDay 
                                    key = {key[0]}
                                    day = {key[0]}
                                    lessons = {data[0]}/>
                            )
                        })}
                    </div>
                    <div onClick={() => handleList(1)} className={right === 30 ? classes.schedule__wrapper_hide : ''}>
                        <img src = {arrow} alt = 'arrow back' className={classes.schedule__wrapper_forward}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    data: state.UserLessons,
    reducer: state.UserLessons
})

export default connect(mapStateToProps, {})(AdminLessons)