import './App.scss';
import {Routes, Route} from 'react-router-dom'
import WelcomePage from './screens/WelcomePage/WelcomePage';
import SignUp from './screens/SignUp/SignUp';
import SignIn from './screens/SignIn/SignIn';
import HomePage from './screens/HomePage/HomePage';
import ForgotPassword from './screens/ForgotPassword/ForgotPassword';

function App() {
  return (
    <Routes>
      <Route path='/' element = {<HomePage />}/>
      <Route path='/welcome' element = {<WelcomePage />}/>
      <Route path='/signup' element = {<SignUp />}/>
      <Route path='/signin' element = {<SignIn />}/>
      <Route path='/forgotPassword' element = {<ForgotPassword />}/>
    </Routes>
  );
}

export default App;
