import React from "react";
import classes from './UserOneLessons.module.scss'

const UserOneLessons = (props) => {
    return(
        <div className={props.paid ? `${classes.lesson} ${classes.lessonGreen}` : `${classes.lesson} ${classes.lessonRed}`}>
               {/* {props.paid && <div>оплачено</div>} */}
            <div className={classes.lesson__type}>
                {props.type}
            </div>
            <div className={classes.lesson__date}>
                {props.dayWeek}{props.day}{props.month}{props.year}
            </div>
            <div className={classes.lesson__time}>
                {props.time}
            </div>
        </div>
    )
}

export default UserOneLessons