import { applyMiddleware, combineReducers, createStore } from "redux";
// import AppReducers from "./AppReducers";
import AuthReducer from './AuthReducer'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import UserLessons from "./UserLessons";

const reducers = combineReducers({
    // AppReducers: AppReducers,
    AuthReducer: AuthReducer,
    UserLessons: UserLessons,
})

export const store = createStore(reducers, 
    composeWithDevTools( applyMiddleware(thunk))   
)
    // applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION && window.__REDUX_DEVTOOLS_EXTENSION())


    // const id =  store.getState().AuthReducer.userId