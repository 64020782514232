import React from "react";
import classes from './WelcomePage.module.scss'
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { isToken } from "../../requestToken/token";
import hugeLogo from '../../img/hugeLogo.svg'
import telegram from '../../img/telegram.svg' 
import facebook from '../../img/facebook.svg'
import instagram from '../../img/instagram.svg'

const WelcomePage = () => {
    return isToken() ? 
    (
        <Navigate to={{ pathname: "/"}}/>
    )
    :
    (
        <div className={classes.welcome}>
            <div className="g-container">
                <div className={classes.welcome__wrapper}>
                    <img alt="logo" className={classes.welcome__wrapper_img} src={hugeLogo}/>
                    <div className={classes.welcome__wrapper__buttons}>
                        <Link to="/signup" className={classes.welcome__wrapper__buttons__singUp}>
                            Зарегистрироваться
                        </Link>
                        <Link to="/signin" className={classes.welcome__wrapper__buttons__singIn}>
                            Войти
                        </Link>
                    </div>
                    <div className={classes.welcome__wrapper__social}> 
                        <a href="https://t.me/studio_romani" target="_blank" rel="noreferrer">
                            <img alt="telegram" className={classes.welcome__wrapper__social_item} src={telegram}/>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100077656080031" target="_blank" rel="noreferrer">
                            <img alt="facebook" className={classes.welcome__wrapper__social_item} src={facebook}/>
                        </a>
                        <a href="https://instagram.com/studio_romani?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
                            <img alt="instagram" className={classes.welcome__wrapper__social_item} src={instagram}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomePage