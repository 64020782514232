import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { createDocumentCollection } from "../requestToken/token";
// import firestore from "./firestore";
// import { db } from '../firebase'
// import { addDoc, collection, getDocs } from 'firebase/firestore'
const USER_DATA = 'USER_DATA'
const FAILED_AUTH_USER = 'FAILED_AUTH_USER'
const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'
const USER_LOG_OUT = 'USER_LOG_OUT'
const MAIN_USER_INFO = 'MAIN_USER_INFO'

const initialState = {
    emailUser: '',
    userId: '',
    accessToken: '',
    errorMessage: '',
    userName: '',
    userSurname: '',
    isAdmin: undefined,
    loading: false,
}

const AuthReducer = (state = initialState, action) => {
    switch(action.type){ 
        case USER_LOG_OUT:
            localStorage.clear('token')
            return{
                ...state,
                emailUser: '',
                userId: '',
                accessToken: '',
                errorMessage: '',
                userName: '',
                userSurname: '',
            }
        case USER_DATA:
            return{
                ...state,
                accessToken: action.userToken,
                errorMessage: '',
            }
        case FAILED_AUTH_USER:
            return{
                ...state,
                errorMessage: action.message
            } 
        case CLEAR_ERROR_MESSAGE:
            return{
                ...state,
                errorMessage: ''
            }    
        case MAIN_USER_INFO: 
            return{
                ...state,
                emailUser: action.emailUser,
                userName: action.userName,
                userSurname: action.userSurname,
                isAdmin: action.isAdmin,
            }
        default:
        return state;
    }
}

export const userLogOut = () => ({type: USER_LOG_OUT})
export const clearErrorState = () => ({type: CLEAR_ERROR_MESSAGE})
export const createNewUser = (email, password, name, surname) => {
    return(dispatch) => {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            // const userEmail =  user.email
            // const userId =  user.uid
            const userToken =  user.accessToken
            // dispatch({type: USER_DATA, userEmail, userId, userToken})
            dispatch({type: USER_DATA, userToken})
            createDocumentCollection(email, name, surname)
            localStorage.setItem('token', userToken);
        })
        .catch((error) => {
            const errorCode = error.code;
            if(errorCode === 'auth/invalid-email' || errorCode === 'auth/user-not-found'){
                dispatch({type: FAILED_AUTH_USER, message: 'Неправильный email'})
            }
            else if(errorCode === 'auth/wrong-password'){
                dispatch({type: FAILED_AUTH_USER, message: 'Неправильный пароль'})
            }
            else if(errorCode === 'auth/email-already-in-use'){
                dispatch({type: FAILED_AUTH_USER, message: 'Пользователь уже существует'})
            }
            else{
                dispatch({type: FAILED_AUTH_USER, message: 'Что-то пошло не так. Повторите, пожалуйта, попытку'})
            }
        });
    }
}

export const requiestUserLogIn = (email, password) => {
    return(dispatch) => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            const userToken =  user.accessToken
            dispatch({type: USER_DATA, userToken})
            localStorage.setItem('token', userToken);
        })
        .catch((error) => {
            const errorCode = error.code;
            if(errorCode === 'auth/user-not-found'){
                dispatch({type: FAILED_AUTH_USER, message: 'Неверный email'})
            }
            if(errorCode === 'auth/wrong-password'){
                dispatch({type: FAILED_AUTH_USER, message: 'Неверный пароль'})
            }
            else{
                dispatch({type: FAILED_AUTH_USER, message: 'Пользователь не найден'})
            }
        });
    }
}

export default AuthReducer