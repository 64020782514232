import React, { useEffect } from "react";
import classes from './HomePage.module.scss'
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { getEmailByToken, getUserInfo, isToken } from "../../requestToken/token";
import { userLogOut } from "../../bll/AuthReducer";
import UserInfo from "../../components/UserInfo/UserInfo";
import AdminInfo from "../../components/AdminInfo/AdminInfo";
import { getLessons } from "../../requestToken/token";
import Loader from "../../components/Loader/Loader";
import logo from '../../img/headerLogo.png'
import exit from '../../img/exit.svg'


const HomePage = (props) => {

  useEffect(() => {
    getUserInfo(getEmailByToken())
    getLessons()
  }, [])

  const handleLogOut = () => {
    props.userLogOut()
  }

  return !isToken()
  ? 
  (
    <Navigate to={{ pathname: "/Welcome"}}/>
  )
  : 
  (
    props.lessons.lessons
    ?
    <div style={classes.wrap}>
      <div className={classes.header}>
        <div className="g-container">
          <div className={classes.header__main}>
            <div className={classes.header__main__logo}>
              <img className={classes.header__main__logo_img} src={logo} alt = 'logo'/>
              <div className={classes.header__main__logo_text}>ROMANI</div>
            </div>
            <div className={classes.header__main__title}>
              <div className={classes.header__main__title_name}>{props.data.userName}</div>
              <div className={classes.header__main__title_surname}>{props.data.userSurname}</div>
            </div>
            <div className={classes.header__main__exit} onClick={handleLogOut}>
              <div className={classes.header__main__exit_text}>выйти</div>
              <img className={classes.header__main__exit_img} alt='exit' src={exit}/>
            </div>
          </div>
        </div>
      </div>
      {props.data.isAdmin
      ? 
        <AdminInfo/>
      :
        <UserInfo />
      }
    </div>
    :
    <div className={classes.loadinWrapper}>
      <Loader />
    </div>
  )
}

const mapStateToProps = (state) => ({
    data: state.AuthReducer,
    lessons: state.UserLessons
})

export default connect(mapStateToProps, {userLogOut})(HomePage)