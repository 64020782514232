import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import classes from './AdminInfo.module.scss'
import { getAdminLessons, getUsersList, makeScheduleAdminLessons } from "../../requestToken/token";
import AdminLessons from "../AdminLessons/AdminLessons";
import AdminSetLessons from "../AdminSetLessons/AdminSetLessons";
import Loader from "../Loader/Loader";
import {startAddingLesson, setFalseUserLessonToAdmin} from "../../bll/UserLessons";
import ManipulateUser from "../ManipulateUser/ManipulateUser";

const AdminInfo = (props) => {
    // console.log('component admin info', props.data)
    const [tab, setTab] = useState(1)

    useEffect(() => {
        getAdminLessons(props.data.lessons)
        getUsersList()
        makeScheduleAdminLessons()
    },[])

    const handleTab = (number) => {
        if(tab === 1 || tab === 2){
            props.setFalseUserLessonToAdmin(false)
        }
        setTab(number)
    }

    const onCreateLesson = () => {
        props.startAddingLesson(true)
    }
    return (
        <div className={classes.tab}>
            <div className="g-container">
                <div className={classes.tab__wrapper}>
                    <button onClick={() => handleTab(1)} className={tab === 1 ? `${classes.tab__wrapper_lessons} ${classes.tab__wrapper_lessons_active}` : classes.tab__wrapper_lessons}>Занятия</button>
                    <button onClick={() => handleTab(2)} className={tab === 2 ? `${classes.tab__wrapper_student} ${classes.tab__wrapper_student_active}` : classes.tab__wrapper_student}>Урок</button>
                    <button onClick={() => handleTab(3)} className={tab === 3 ? `${classes.tab__wrapper_student} ${classes.tab__wrapper_student_active}` : classes.tab__wrapper_student}>Ученик</button>
                </div>
            </div>
            {
            // props.data.adminLessons?.length > 0
            props.lessons.isLoadLessons
            ?
            <div>
                {(() => {
                    switch (tab) {
                    case 1:
                        return <AdminLessons 
                                lessons = {props.data.adminLessons}/>
                    case 2:
                        return <AdminSetLessons 
                                userList = {props.data.listUsers} 
                                isLoading={props.lessons.isLoadingAddLesson}
                                onClick={onCreateLesson}/>
                    case 3:
                        return <ManipulateUser
                                userList = {props.data.listUsers} />
                    default:
                        return null
                    }
                })()}
                {/* <p>123</p> */}
            </div>
            :
            <div className={classes.loadinWrapper}>
              <Loader />
            </div>
            }
        </div>
    )
}

// export default AdminInfo

const mapStateToProps = (state) => ({
    data: state.UserLessons,
    lessons: state.UserLessons
})

export default connect(mapStateToProps, {startAddingLesson, setFalseUserLessonToAdmin})(AdminInfo)