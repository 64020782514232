import React, {useState, useEffect} from "react";
import classes from './SignUp.module.scss'
import { Link } from "react-router-dom";
import {createNewUser, clearErrorState} from "../../bll/AuthReducer";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { isToken } from "../../requestToken/token";
import hugeLogo from '../../img/hugeLogo.svg';
import Loader from "../../components/Loader/Loader";

const SignUp = (props) => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [emailAgain, setEmailAgain] = useState('')
    const [password, setPassword] = useState('')
    const [passwordAgain, setPasswordAgain] = useState('')
    const [surname, setSurname] = useState('')
    const [isDisabled, setDisabled] = useState(true)
    const [error, setError] = useState('')
    const [isLoading, setLoading] = useState(false)
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/; 

    const handleForm = (email, password, name, surname) => {
        props.clearErrorState()
        if(regex.test(email) && password === passwordAgain && password.length > 6 && name.length > 1 && surname.length > 1 && email === emailAgain) {
            setLoading(true)
            props.createNewUser(email, password, name, surname)
        }
        else{
            setDisabled(true)
            if(password !== passwordAgain) {
                setError('Пароли не совпадают')
                setPassword('')
                setPasswordAgain('')
            }
            else if(password.length < 7) {
                setError('Пароль должен содержать более 6 символов')
                setPassword('')
                setPasswordAgain('')
            }
            else if(name.length < 2 || surname.length < 2) {
                setError('Имя и Фамилия не могут быть пустыми')
                setSurname('')
                setName('')
            }
            else if(email !== emailAgain){
                setError('Email не совпадают')
                setEmailAgain('')
            }
            else if (!regex.test(email)){
                setError('Email неправильный')
                setEmail('')
                setEmailAgain('')
            }
        }
    }

    useEffect(() => {
        if(email && password && passwordAgain && name && surname && emailAgain){
            setDisabled(false)
        }
        else(
            setDisabled(true)
        )
    },[email, password, passwordAgain, name, surname, emailAgain ]) 

    useEffect(() => {
        props.clearErrorState()
    },[]) 

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        } , 500)
    },[props.data.errorMessage]) 

    return isToken()
    ?
    (
        <Navigate to={{ pathname: "/"}}/>
    )
    :
    (
        isLoading
        ?
            <div className={classes.loadinWrapper}>
                <Loader />
            </div>
        :
            <div className={classes.signUp}>
                <div className="g-container">
                    <div className={classes.signUp__wrapper}>
                        <div className={classes.signUp__wrapper__header}>
                            <img alt="logo" className={classes.signUp__wrapper__header_img} src={hugeLogo}/>
                            <div className={classes.signUp__wrapper__header__text}>
                                <p className={classes.signUp__wrapper__header__text_title}>Регистрация</p>
                                <Link to = '/signIn' className={classes.signUp__wrapper__header__text_link}>ВХОД</Link>
                            </div>
                        </div>
                        <div className={classes.signUp__wrapper__main}>
                            <input 
                                type={'email'} 
                                value={email} 
                                onChange = {e => setEmail(e.target.value)} 
                                placeholder = 'Ваш email'
                                className={classes.signUp__wrapper__main_input}/>
                            <input 
                                type={'email'} 
                                value={emailAgain} 
                                onChange = {e => setEmailAgain(e.target.value)} 
                                placeholder = 'Ваш email еще раз'
                                className={classes.signUp__wrapper__main_input}/>
                            <input 
                                type={'password'} 
                                value={password} 
                                onChange = {e => setPassword(e.target.value)} 
                                placeholder = 'Ваш пароль'
                                className={classes.signUp__wrapper__main_input}/>
                            <input 
                                type={'password'} 
                                value={passwordAgain} 
                                onChange = {e => setPasswordAgain(e.target.value)} 
                                placeholder = 'Повторите пароль'
                                className={classes.signUp__wrapper__main_input}/>
                            <div className={classes.signUp__wrapper__main__wrapperInput}>
                                <input 
                                    type={'name'} 
                                    value={name} 
                                    onChange = {e => setName(e.target.value)} 
                                    placeholder = 'Ваше имя'
                                    className={classes.signUp__wrapper__main_inputName}/>
                                <input 
                                    type={'name'} 
                                    value={surname} 
                                    onChange = {e => setSurname(e.target.value)} 
                                    placeholder = 'Ваша фамилия'
                                    className={classes.signUp__wrapper__main_inputName}/>
                            </div>
                            <div className={classes.signUp__wrapper__main__errorState}>
                                <p>{props.data.errorMessage}</p>
                                <p>{error}</p>
                            </div>
                        </div>
                        <div className={classes.signUp__wrapper__buttons}>
                            <button onClick = {() => handleForm(email, password, name, surname)} className={classes.signUp__wrapper__buttons_singUp} disabled={isDisabled}>Зарегистрироваться</button>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = (state) => ({
    data: state.AuthReducer
})

export default connect(mapStateToProps, {createNewUser, clearErrorState})(SignUp)