import React, {useState, useEffect} from "react";
import classes from './SignIn.module.scss'
import { Link } from "react-router-dom";
import { requiestUserLogIn, clearErrorState} from "../../bll/AuthReducer";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { isToken } from "../../requestToken/token";
import hugeLogo from '../../img/hugeLogo.svg'
import Loader from "../../components/Loader/Loader";

const SignIn = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isDisabled, setDisabled] = useState(true) 
    const [error, setError] = useState('')
    const [isLoading, setLoading] = useState(false)
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const changeInput = (setInput, value) => {
        setInput(value)
        setError('')
    }

    const handleForm = (email, password) => {
        props.clearErrorState()
        if(regex.test(email)  && password.length > 6){
            setLoading(true)
            props.requiestUserLogIn(email, password)
        }
        else{
           if(password.length < 7){
            setError('Пароль должен содержать более 6 символов')
            setPassword('')
            setDisabled(true)
           }
           else{
            setError('Email неправильный')
            setEmail('')
            setDisabled(true)
           }
        }
    }

    useEffect(() => {
        if(email && password){
            setDisabled(false)
        }
        else(
            setDisabled(true)
        )
    },[email, password ]) 

    useEffect(() => {
        setPassword('')
        setTimeout(() => {
            setLoading(false)
        } , 500)
    },[props.data.errorMessage]) 

    useEffect(() => {
        props.clearErrorState()
    },[]) 

    return isToken()
    ?
    (
        <Navigate to={{ pathname: "/"}}/>
    ) 
    :
    (
        isLoading
        ?
            <div className={classes.loadinWrapper}>
                <Loader />
            </div>
        :
        <div className={classes.signIn}>
        <div className="g-container">
            <div className={classes.signIn__wrapper}>
                <div className={classes.signIn__wrapper__header}>
                    <img alt="logo" className={classes.signIn__wrapper__header_img} src={hugeLogo}/>
                    <div className={classes.signIn__wrapper__header__text}>
                        <p className={classes.signIn__wrapper__header__text_title}>ВХОД</p>
                        <Link to = '/signUp' className={classes.signIn__wrapper__header__text_link}>Регистрация</Link>
                    </div>
                </div>
                <div className={classes.signIn__wrapper__info}>
                    <input 
                        className={classes.signIn__wrapper__info_input}
                        type={'email'} 
                        value={email} 
                        onChange = {e => changeInput(setEmail, e.target.value)} 
                        placeholder = 'Введите email'/>
                    <input 
                        className={classes.signIn__wrapper__info_inputPassword}
                        type={'password'} 
                        value={password} 
                        onChange = {e => changeInput(setPassword, e.target.value)} 
                        placeholder = 'Введите password'/>
                    <div className={classes.signIn__wrapper__info__error}>
                        {error && <p>{error}</p>}
                        <p>{props.data.errorMessage}</p>
                    </div>
                </div>
                <div className={classes.signIn__wrapper__buttons}>
                    <button onClick = {() => handleForm(email, password)} className={classes.signIn__wrapper__buttons_singIn} disabled={isDisabled}>Войти</button>
                    <Link to = '/forgotPassword' className={classes.signIn__wrapper__buttons_forgot}>Забыли пароль?</Link>
                </div>
            </div>
        </div>
    </div>
    )
}

const mapStateToProps = (state) => ({
    data: state.AuthReducer
})

export default connect(mapStateToProps, {requiestUserLogIn, clearErrorState})(SignIn)