import React, {useState} from "react";
import { deleteuserLessonByAdmin } from "../../requestToken/token";
import classes from './UserLessonToAdmin.module.scss'

const UserLessonToAdmin = (props) => {

    const [isChangeUserData, setChangeUserData] = useState(false)
    const [changePriceStatus, setPriceStatus] = useState()

    const changeButtonStatus = (status) => {
        setChangeUserData(true)
        setPriceStatus(status)
    }

    // const canselTheLesson = () => {
    //     console.log('cancel')
    //     deleteuserLessonByAdmin()
    // }

    // const lessonAlreadyPaid = () => {
    //     console.log('paid')
    // }

    // const lessonAlreadyUnpaid = () => {
    //     console.log('Unpaid')
    // }

    return (
        <div className={classes.main}>
            <div className={classes.full}>
                <div className={classes.full__mainInfo}>
                    <div className={classes.full__mainInfo__date}>
                        <p className={classes.full__mainInfo__date_date}>{props.day}{props.month}</p>
                        <p className={classes.full__mainInfo__date_day}>{props.numberDayWeek}</p>
                        <p className={classes.full__mainInfo__date_time}>{props.time}</p>
                    </div>
                    <p className={classes.full__mainInfo_type}>{props.type}</p>
                </div>
                {props.isPaid ?
                    <button className={classes.full_paid_true} onClick={() => changeButtonStatus(true)}>Оплачено</button>
                :
                    <button className={classes.full_paid_false} onClick={() => changeButtonStatus(true)}>Оплатить</button>
                }
                <button className={classes.full_cancel} onClick={() => changeButtonStatus(false)}>Отменить</button>
                {/* onClick={fitdIDCollectionOneLesson} */}
            </div>
            {isChangeUserData ?
                changePriceStatus?
                    <div className={classes.full__approveBlock}>
                        <div style = {{opacity: 0}}>123</div>
                        {
                            props.isPaid ? 
                                <div className={classes.full__approveBlock__main}>
                                    <p>Изменить статус на НЕоплачено</p>
                                    <button className={classes.full__approveBlock_button} onClick={() => props.lessonAlreadyUnpaid(props.idCollection, props.timestamp, props.name, props.surname)}>Изменить</button>
                                </div>
                            :
                                <div className={classes.full__approveBlock__main}>
                                    <p>Изменить статус на оплачено</p>
                                    <button className={classes.full__approveBlock_button} onClick={() => props.lessonAlreadyPaid(props.idCollection, props.timestamp, props.name, props.surname)}>Изменить</button>
                                </div>
                        }
                        <p onClick={() => setChangeUserData(false)} className={classes.full__approveBlock_close}>закрыть</p>
                    </div>
                :
                    <div className={classes.full__approveBlock}>
                        <div style = {{opacity: 0}}>123</div>
                        <div className={classes.full__approveBlock__main}>
                            <p>Вы уверены что хотите отменить занятие</p>
                            <button className={classes.full__approveBlock_button_error} onClick={() => props.canselTheLesson(props.idCollection, props.timestamp, props.name, props.surname)}>Отменить</button>
                        </div>
                        <p onClick={() => setChangeUserData(false)} className={classes.full__approveBlock_close}>закрыть</p>
                    </div>
                :
                <div style = {{opacity: 0}}>123</div>
            }
        </div>
    )
}

export default UserLessonToAdmin