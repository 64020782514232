import React,{useState} from "react";
import { adminConvertMonth, adminDayWeek } from "../../requestToken/token";
import ItemAdminOneLesson from "../ItemAdminOneLesson/ItemAdminOneLesson";
import classes from './ItemAdminOneDay.module.scss';


const ItemAdminOneDay = (props) => {
    const date = new Date(props.day)
    const [dayOfTheWeek, setDayOfTheWeek] = useState(adminDayWeek(date.getDay()))
    const [month, setMonth] = useState(adminConvertMonth(date.getMonth()))
    const [day, setDay] = useState(date.getDate())
    return(
        <div className={classes.oneDay}>
            <div className={classes.oneDay__wrapper}>
                <div className={classes.oneDay__wrapper__date}>
                    <p className={classes.oneDay__wrapper__date_day}>{day}{month}</p>
                    <p className={classes.oneDay__wrapper__date_dayWeek}>{dayOfTheWeek}</p>
                </div>
                <div className={classes.oneDay__wrapper__lessons}>
                    {props.lessons[0]?.time ?
                    props.lessons.map((el) => {
                        return(
                            <ItemAdminOneLesson 
                                time = {el.time}
                                name = {el.name}
                                surname = {el.surname}
                                key = {el.key}
                                isPaid = {el.paid}
                            />
                        )
                    })
                    :
                    <p className={classes.oneDay__wrapper__lessons_noLessons}>нет занятий</p>}
                </div>
            </div>
        </div>
    )
}

export default ItemAdminOneDay