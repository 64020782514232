import React from "react";
import classes from './ItemAdminOneLesson.module.scss'

const ItemAdminOneLesson = (props) => {
    return(
        <div className={classes.lesson} style = {props.isPaid ? {color: 'green'} : {color: 'red'}}>
            <p className={classes.lesson_time}>{props.time}</p>
            <p className={classes.lesson_name}>{props.name}</p>
            <p className={classes.lesson_surname}>{props.surname}</p>
        </div>
    )
}

export default ItemAdminOneLesson