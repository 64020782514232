import React, {useEffect, useState} from "react";
import classes from './ManipulateUser.module.scss'
import { connect } from "react-redux";
import { convertKeyToTimestamp, deleteuserLessonByUser, getUserFutureLessons, paidUpdateUserLessonByUser } from "../../requestToken/token";
import ItemShowAdminUserList from "../ItemShowAdminUserList/ItemShowAdminUserList";
import { setLoadingUsersLessonsToAdmin, setTurnOffLoadingLesson } from "../../bll/UserLessons";
import UserLessonToAdmin from "../UserLessonToAdmin/UserLessonToAdmin";
import Loader from "../Loader/Loader";
// import { getAuth } from "firebase/auth";

const ManipulateUser = (props) => {

    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [showUserList, setShowUserList] = useState(false)

    const handleShowList = (email, name, surname) => {
        setEmail(email)
        setShowUserList(prev => !prev)
        setUserName(name + " " + surname)
        props.setLoadingUsersLessonsToAdmin(false)
        getUserFutureLessons(email)
    }

    // const deleteUser = () => {
    //     console.log('delete', email)
    //     deleteUser(email)
    //     .then(() => {
    //         console.log('success')
    //     })
    //     .catch((e) => {
    //         console.log('failed', e.message)
    //     })
    // }

    const canselTheLesson = (id, key, name, surname) => {
        props.setTurnOffLoadingLesson(true)
        deleteuserLessonByUser(id, convertKeyToTimestamp(key), name, surname)
    }

    const lessonAlreadyPaid = (id, timestamp, name, surname) => {
        props.setTurnOffLoadingLesson(true)
        const status = true;
        paidUpdateUserLessonByUser(id, convertKeyToTimestamp(timestamp), name, surname, status)
    }

    const lessonAlreadyUnpaid = (id, timestamp, name, surname) => {
        props.setTurnOffLoadingLesson(true)
        const status = false;
        paidUpdateUserLessonByUser(id, convertKeyToTimestamp(timestamp), name, surname, status)
    }

    return !props.reducer.isChangingLessonData ? (
        <div className={classes.user}>
            <div className="g-container">
                <div className={classes.user__wrapper}>
                    <input 
                        className={classes.user__wrapper_chooseStudent}
                        placeholder={'Выберите ученика'}
                        onClick={() => setShowUserList(prev => !prev)}
                        value={userName}
                        onChange={() => {}}
                    />
                    {showUserList &&
                        props.userList &&
                        <div className={classes.user__wrapper_usersList}>
                            {props.userList.map((el) => {
                                return(
                                    <ItemShowAdminUserList 
                                        key={el.email}
                                        email = {el.email}
                                        name = {el.name}
                                        surname = {el.surname}
                                        closeForm = {() => handleShowList(el.email, el.name, el.surname)}
                                    />
                                )
                            })}
                        </div>
                    }
                    {
                        props.reducer.isUserLessonsToAdmin ? 
                            props.reducer.getUserLessonsToAdmin.length ?
                            <div className={classes.user__wrapper_usersLessons}>
                                {
                                    props.reducer.getUserLessonsToAdmin.map((el) => {
                                        return(
                                            <UserLessonToAdmin 
                                                key = {el.key}
                                                timestamp = {el.key}
                                                day = {el.day}
                                                month = {el.month}
                                                numberDayWeek = {el.numberDayWeek}
                                                time = {el.time}
                                                isPaid = {el.paid}
                                                type = {el.type}
                                                idCollection = {el.idCollection}
                                                name = {el.name}
                                                surname = {el.surname}
                                                canselTheLesson = {canselTheLesson}
                                                lessonAlreadyPaid = {lessonAlreadyPaid}
                                                lessonAlreadyUnpaid = {lessonAlreadyUnpaid}
                                                />
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className={classes.user__wrapper__emptyUsersLessons}>
                                <p className={classes.user__wrapper__emptyUsersLessons_text}>На данных момент занятий нет</p>
                            </div>
                            :<div style = {{opacity: 0}}>
                                456
                            </div>
                    }

                </div>
            </div>
        </div>
    )
    : 
    (
        <div className={classes.loadinWrapper}>
            <Loader />
        </div>
    )
}

const mapStateToProps = (state) => ({
    data: state.UserLessons,
    reducer: state.UserLessons
})

export default connect(mapStateToProps, {setLoadingUsersLessonsToAdmin, setTurnOffLoadingLesson})(ManipulateUser)